.footer {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8vh;
    position: relative;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(255, 0, 61, 0.21) 49.75%, rgba(255, 0, 46, 0) 100%), #F15A24;
    &__intro {
        position: fixed;
        width: 100%;
        bottom: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    &:after {
        content: '';
        position: absolute;
        width: 150px;
        height: 80px;
        right: -49px;
        bottom: -21px;
        background-image: url(../img/diamond_white.svg);
        background-size: cover;
    }
    &__left {
        display: flex;
        align-items: center;
    }
    &__right {
        padding-right: 80px;
        a {
            font-size: 15px;
            line-height: 24px;
            color: #fff;
            text-decoration: none;
        }
    }
}

@media only screen and (max-width: 767px) {

    .footer {
        &__left {
            display: none;
        }
    }

}