.content-page {
    padding-top: 250px;
    &__bg {
        height: 60vh;
        -webkit-background-size: cover;
        background-size: cover;
        &--overview {
            background-image: url(../img/bg/img-overview.jpg);
        }
        &--principles {
            background-image: url(../img/bg/img-prinсiple.jpg);
        }
        &--capabilities {
            background-image: url(../img/bg/img-capabilities.jpg);
        }
        &--team {
            background-image: url(../img/bg/img-team.jpg);
        }
        &--services {
            background-image: url(../img/bg/img-service.jpg);
        }
        &--careers {
            background-image: url(../img/bg/img-careers.jpg);
        }
        &--compliance {
            background-image: url(../img/bg/img-compliance.jpg);
        }
        &--contacts {
            background-image: url(../img/bg/img-contacts.jpg);
        }
    }
    &__block {
        position: relative;
        width: 550px;
        top: -55vh;
        left: 0;
        box-shadow: 0px 16px 48px rgba(19, 32, 64, 0.08);
        border-radius: 16px;
        background-color: #fff;
        padding: 60px 100px;
        margin-bottom: -45vh;
        &--short {
            top: -50px;
            margin-bottom: -20px;
        }
        &__wrap {
            display: flex;
            justify-content: flex-end;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 3px;
                background-image: linear-gradient($orange 33%, rgba(255,255,255,0) 0%);
                background-position: right;
                background-size: 3px 15px;
                background-repeat: repeat-y;
                z-index: 0;
            }
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 20%;
                transform: translate(-50%);
                width: 282px;
                height: 324px;
                background-image: url(../img/diamond_orange.svg);
                -webkit-background-size: cover;
                background-size: cover;
                z-index: 2;
            }
            &__wrap {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 20%;
                    transform: translate(-50%);
                    width: 282px;
                    height: 324px;
                    background-color: #fff;
                    z-index: 1;
                }
            }
        }
        &__title {
            font-weight: 600;
            font-size: 48px;
            line-height: 56px;
            color: $font;
            margin-bottom: 60px;
        }
        &__subtitle {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: $font;
        }
        &__item {
            position: relative;
            padding-top: 50px;
            margin-bottom: 70px;
            &__wrap {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    top: -82px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 67px;
                    height: 67px;
                    background-color: #fff;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
            & ul {
                margin-left: 20px;
                margin-top: 20px;
            }
            &__text {
                position: relative;
                font-size: 15px;
                line-height: 24px;
                color: $font;
            }
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                top: 0;
                left: 0;
                border-top: 2px dotted $orange;
                z-index: 0;
            }
            &:before {
                content: '';
                position: absolute;
                top: -32px;
                left: 50%;
                transform: translateX(-50%);
                width: 64px;
                height: 64px;
                background-repeat: no-repeat;
                background-position: center center;
                z-index: 2;
            }
            &--pointers {
                &:before {
                    background-image: url(../img/icons/pointers.svg);
                }
            }
            &--handshake {
                &:before {
                    background-image: url(../img/icons/handshake.svg);
                }
            }
            &--chart {
                &:before {
                    background-image: url(../img/icons/chart.svg);
                }
            }
            &--hills {
                &:before {
                    background-image: url(../img/icons/hills.svg);
                }
            }
            &--star {
                &:before {
                    background-image: url(../img/icons/star.svg);
                }
            }
            &--integrity {
                &:before {
                    background-image: url(../img/icons/integrity.svg);
                }
            }
            &--list-checked {
                &:before {
                    background-image: url(../img/icons/list-checked.svg);
                }
            }
            &--suitcase {
                &:before {
                    background-image: url(../img/icons/suitcase.svg);
                }
            }
            &--diamond {
                &:before {
                    background-image: url(../img/icons/diamond.svg);
                }
            }
            &--clock {
                &:before {
                    background-image: url(../img/icons/clock.svg);
                }
            }
            &--pie-chart {
                &:before {
                    background-image: url(../img/icons/pie-chart.svg);
                }
            }
            &--scales {
                &:before {
                    background-image: url(../img/icons/scales.svg);
                }
            }
            &--box {
                &:before {
                    background-image: url(../img/icons/box.svg);
                }
            }
            &--latvia {
                &:before {
                    background-image: url(../img/icons/latvia.svg);
                }
            }
            &--kazakhstan {
                &:before {
                    background-image: url(../img/icons/kazakhstan.svg);
                }
            }
            &--belarus {
                &:before {
                    background-image: url(../img/icons/belarus.svg);
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {

    .content-page {
        &__block {
            &--short {
                top: -55vh;
                margin-bottom: -45vh;
            }
            &__wrap {
                &:before,
                &:after {
                    display: none;
                }
                &__wrap {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 475px) {

    .content-page {
        &__block {
            padding: 40px 50px;
            &__title {
                font-size: 32px;
                line-height: 48px;
            }
        }
    }

}

