.header {
    background-color: $bg-main;
    padding: 50px 0;
    height: 25vh;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 250px;
    position: absolute;
    width: 100%;
    &__internal {
        min-height: 250px;
        .header__logo {
            img {
                width: 200px;
            }
        }
    }
    &__logo {
        display: flex;
        justify-content: center;
        img {
            width: 200px;
        }
    }
    &__nav {
        padding-top: 50px;
        &__list {
            padding-left: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            &__item {
                a {
                    text-transform: uppercase;
                    color: $orange;
                    font-weight: 500;
                    text-decoration: none;
                    letter-spacing: 2px;
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }
}

.menu {
    display: flex;
    justify-content: center;
    list-style-type: none;
    text-align: center;
    height: 100%;
    position: relative;
    &-item {
        width: 16%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &-has-children {
            & > a {
                position: relative;
                padding-right: 15px;
                &:after {
                    content: '';
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    right: -12px;
                    top: 50%;
                    transform: translateY(-57%);
                    background-image: url(../img/arrow-bottom-orange.svg);
                    background-size: cover;
                }
            }
            &:hover {
                .sub-menu {
                    visibility: visible;
                    opacity: 1;
                    top: 100%;
                }
            }
        }
        &.current_page_item {
            a {
                color: $font !important;
                border-bottom: 1px solid #000 !important;
            }
        }
        &.current-menu-parent {
            & > a {
                color: $font !important;
                border-bottom: 1px solid #000 !important;
                &:after {
                    background-image: url(../img/arrow-bottom-black.svg);
                }
            }
        }
        a {
            display: block;
            height: 100%;
            color: $orange;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 16px;
            line-height: 28px;
            &:hover,
            &:focus,
            &:active,
            &:visited{
                color: $orange;
            }
        }
    }

}

.sub-menu {
    visibility: hidden;
    opacity: 0;
    list-style-type: none;
    position: absolute;
    top: 150%;
    transition: all 300ms ease-in;
    margin-left: 20px;
    left: 0;
    z-index: 10;
    padding: 0 30px;
    background-color: $bg-main;
    & .menu-item {
        text-align: left;
        width: 200px;
        justify-content: left;
        a{
            padding: 15px 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 16px;
            line-height: 28px;
            border-bottom: 1px dashed $orange;
        }
        &:last-child {
            a {
                border: none !important;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {

    .sub-menu {
        margin-left: 7px;
    }

}

@media only screen and (max-width: 992px) {

    .header {
        &__nav {
            padding-top: 64px;
            position: absolute;
            width: 100%;
            left: 0;
            top: 186px;
            z-index: 998;
            height: 100vh;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            overflow-y: scroll;
            display: none;
            &.show {
                display: block;
            }
        }
    }

    .menu-item-has-children a {
        padding-right: 0;
    }

    .menu-menu-container {
        height: 100%;
    }

    .nav {
        //z-index: 999;
        &__main {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: 998;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            overflow-y: scroll;
            &.show {
                display: block;
            }
            &__list {
                visibility: hidden;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 80px;
                background-color: rgba(56,94,195,.9);
                opacity: 0;
                transition: all 300ms ease-in;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                &.show {
                    visibility: visible;
                    opacity: 1;
                    transition: all 300ms ease-in;
                }
                &__item {
                    width: 100%;
                    text-align: left;
                    &--has-children {
                        position: relative;
                        &:after {
                            content: '';
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            right: 20px;
                            top: 40%;
                            transform: rotate(-90deg);
                            background-image: url(../img/arrow-bottom.svg);
                        }
                        span {
                            margin: 0;
                            &:after {
                                display: none;
                            }
                        }
                    }
                    a {
                        padding-left: 40px;
                    }
                }
            }
        }
    }

    //WP

    .menu-main-ru-container,
    .menu-main-en-container{
        height: auto;
    }

    .menu-item-34 {
        &>a {
            display: none;
        }
    }

    .menu{
        visibility: hidden;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 30px;
        background: linear-gradient(180deg, rgba(255, 0, 61, 0.21) 49.75%, rgba(255, 0, 46, 0) 100%), #F15A24;
        opacity: 0;
        transition: all 300ms ease-in;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding-bottom: 200px;
        display: none;
        &.show {
            visibility: visible;
            opacity: 1;
            transition: all 300ms ease-in;
            overflow-x: hidden;
            display: block;
        }
        &-item {
            width: 100%;
            text-align: left;
            &-has-children {
                position: relative;
                display: flex;
                flex-direction: column;
                //&:after {
                //    content: '';
                //    width: 20px;
                //    height: 20px;
                //    position: absolute;
                //    right: 20px;
                //    top: 40%;
                //    transform: rotate(-90deg);
                //    background-image: url(../img/arrow-bottom.svg);
                //}
                &:hover {
                    .submenu {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                a {
                    margin: 0;
                    &:after {
                        display: none;
                    }
                }
            }
            a {
                padding-left: 0;
                color: #fff;
                margin: 15px 0;
                height: auto;
                &:hover,
                &:focus,
                &:active,
                &:visited{
                    color: #fff;
                }
            }
        }
    }

    .sub-menu {
        visibility: visible;
        opacity: 1;
        position: static;
        width: 100%;
        z-index: 1;
        background-color: transparent;
        margin-left: 0;
        .menu-item {
            width: 100%;
            text-align: center;
            justify-content: center;
            a {
                padding: 0;
                font-size: 16px;
            }
        }
    }

    .hamburger{
        text-align: center;
        z-index: 999;
        position: relative;
        top: 40px;
    }

    /*/////////////////////////////////////////////*/

    /*HAMBURGER ICON EFFECT*/

    /*/////////////////////////////////////////////*/

    .menuh {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 7px 7px 7px;
        z-index: 2;
    }
    .menuh span {
        margin: 0 auto;
        position: relative;
        top: 12px;
    }
    .menuh span:before, .menuh span:after {
        position: absolute;
        content: '';
    }
    .menuh span, .menuh span:before, .menuh span:after {
        width: 25px;
        height: 3px;
        background-color: red;
        display: block;
    }
    .click .menuh span:before, .click .menuh span:after{
        background-color: #fff;
    }
    .click .menuh span{
        background-color: transparent;
    }
    .menuh span:before {
        margin-top: -9px;
    }
    .menuh span:after {
        margin-top: 9px;
    }
    .example5 span {
        -webkit-transition-duration: 0s;
        transition-duration: 0s;
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }
    .click span {
        background-color: rgba(0, 0, 0, 0.0);
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }
    .example5 span:before {
        -webkit-transition-property: margin, -webkit-transform;
        transition-property: margin, transform;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-delay: 0.2s, 0s;
        transition-delay: 0.2s, 0s;
    }
    .click span:before {
        margin-top: 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition-delay: 0s, 0.2s;
        transition-delay: 0s, 0.2s;
    }
    .example5 span:after {
        -webkit-transition-property: margin, -webkit-transform;
        transition-property: margin, transform;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-delay: 0.2s, 0s;
        transition-delay: 0.2s, 0s;
    }
    .click span:after {
        margin-top: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transition-delay: 0s, 0.2s;
        transition-delay: 0s, 0.2s;
    }

}