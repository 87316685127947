@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";
@import "vendor/libs";
@import "base/fonts";
@import "base/general";
@import "../blocks/modules";

body {
    font-family: 'Fira Sans', sans-serif;
}