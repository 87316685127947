.card{
    cursor: default;
    height: 1570px;
    overflow: hidden;
    padding-top: 250px;
    &-tab {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        top: -35vh;
    }
    &__section {
        &__wrap {
            @include col();
            @include size-xl(4);
            @include size-lg(4);
            @include size-md(4);
            @include size-sm(12);
            background-color: #fff;
            box-shadow: 0px 16px 48px rgba(19, 32, 64, 0.08);
            border-radius: 16px;
        }
    }
    &__main {
        &__wrap {
            @include col();
            @include size-xl(7);
            @include size-lg(7);
            @include size-md(7);
            @include size-sm(12);
            background-color: #fff;
            box-shadow: 0px 16px 48px rgba(19, 32, 64, 0.08);
            border-radius: 16px;
            padding: 40px 90px;
        }
        &__info {
            border-bottom: 2px dotted $orange;
            padding-bottom: 20px;
            &__title {
                font-weight: 600;
                font-size: 48px;
                line-height: 56px;
                color: $font;
            }
            &__text {
                font-size: 15px;
                line-height: 24px;
                color: $font;
            }
        }
    }
    &__block{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0 24px 60px;
        margin-right: 20px;
        cursor: pointer;
        &:first-child{
            margin-top: 30px;
        }
        &__link{
            &__pict{
                background-color: #C1E5EC;
                width: 137px;
                height: 90px;
                position: relative;
                &__img--1{
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 15%;
                    &--position{
                        left: 0;
                    }
                }
                &__img--2{
                    display: none;
                }
            }
            &:focus,
            &:active,
            &:hover{
                & .card__block__link__pict{
                    background-color: #27a5be !important;
                    &__img--1{
                        display: none;
                    }
                    &__img--2{
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 15%;
                        &--position{
                            left: 0;
                        }
                    }
                }
            }
        }
        &__text{
            &__heading{
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                color: $font;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
            &__bodying{
                font-size: 15px;
                line-height: 24px;
                color: #ABABAB;
                font-weight: normal;
            }
        }
    }
    &__block--active{
        background: linear-gradient(180deg, rgba(255, 0, 61, 0.21) 49.75%, rgba(255, 0, 46, 0) 100%), #F15A24;
        .card__block__text{
            &__heading{
                color: #fff;
            }
            &__bodying{
                color: #fff;
            }
        }
        & .card__block__link__pict{
            background-color: #27a5be !important;
            &__img--1{
                display: none;
            }
            &__img--2{
                display: block;
                position: absolute;
                bottom: 0;
                left: 10%;
                &--position{
                    left: 0;
                }
            }
        }
    }
    &__main{
        display: none;
        &.active {
            display: block;
        }
        &__heading{
            display: flex;
            &__pict{
                width: 100%;
                height: 250px;
                background: linear-gradient(180deg, rgba(255, 0, 61, 0.21) 49.75%, rgba(255, 0, 46, 0) 100%), #F15A24;
                margin-top: 120px;
                position: relative;
                right: -90px;
                & img{
                    margin-top: -120px;
                    height: 370px;
                }
            }
            &__text{
                width: 168px;
                margin-top: 55px;
                margin-left: 30px;
                &--title{
                    font-size: 24px;
                    color: #666;
                    margin: 0;
                }
                &--name{
                    font-size: 48px;
                    color: #232020;
                    text-transform: uppercase;
                    margin: 0;
                    line-height: 61px;
                    position: relative;
                    left: -62px;
                }
                &--text{
                    font-size: 18px;
                    font-weight: 100;
                    color: #666;
                    margin: 0;
                    margin-top: 12px;
                    margin-bottom: 10px;
                }
                &--subtitle{
                    font-size: 24px;
                    color: #1E90AF;
                    text-transform: uppercase;
                    margin: 0;
                }
            }
        }
        &__bodying{
            margin-top: 70px;
            text-align: left;
            h4 {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: $font;
            }
            & p{
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 24px;
                color: $font;
            }
        }
    }
}

.hidden{
    display: none;
    border: none;
}

@media only screen and (max-width: 767px) {


    .card {
        &-tab {
            top: -50vh;
        }
        .container {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }
        &__section {
            &__wrap {
                position: absolute;
                width: 22%;
                left: 0;
                margin-right: 0;
            }
        }
        &__main {
            &__wrap {
                position: absolute;
                width: 70%;
                right: 0;
                padding: 40px 30px;
            }
            &__heading {
                &__pict {
                    right: 0;
                }
            }
            &__bodying {
                p {
                    font-size: 13px;
                    line-height: 19px;
                }
            }
        }
        &__block {
            padding: 24px 0 24px 10px;
            margin-right: 0;
            padding-right: 10px;
            &__text {
                &__heading {
                    font-size: 14px;
                    line-height: 22px;
                }
                &__bodying {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

}

@media only screen and (max-width: 500px) {

    .card {
        .container {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }
        &__section {
            &__wrap {
                position: absolute;
                width: 27%;
                left: 0;
                margin-right: 0;
                margin-left: 0;
            }
        }
        &__main {
            &__wrap {
                position: absolute;
                width: 70%;
                right: 0;
                padding: 40px 30px;
                margin-right: 0;
            }
            &__heading {
                &__pict {
                    right: 0;
                    margin-top: 140px;
                    height: 110px;
                    width: 125%;
                    img {
                        height: 230px;
                    }
                }
            }
            &__bodying {
                p {
                    font-size: 13px;
                }
            }
            &__info {
                &__title {
                    font-size: 34px;
                    line-height: 42px;
                }
                &__text {
                    font-size: 13px;
                    line-height: 19px;
                }
            }
        }
        &__block {
            padding: 24px 0 24px 5px;
            margin-right: 0;
            padding-right: 10px;
            &__text {
                &__heading {
                    font-size: 11px;
                    line-height: 20px;
                    letter-spacing: 1px;
                }
                &__bodying {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

}