.coming-soon {
    height: 100vh;
    overflow: hidden;
    &__header {
        height: 25vh;
        display: flex;
        flex-direction: row;
        &__left {
            background-image: url(../img/bg.png);
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            width: 75%;
            display: flex;
            align-items: center;
            padding-left: 5%;
            &__logo {
                height: 70%;
            }
        }
        &__right {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            //font-family: 'Work Sans', sans-serif;
            &__name {
                color: #F15A24;
                font-size: 2vw;
            }
            &__address {
                color: #272423;
                font-size: 1.2vw;
            }
        }
    }
    &__main {
        position: relative;
        height: 75vh;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(255, 0, 61, 0.21) 49.75%, rgba(255, 0, 46, 0) 100%), #F15A24;
        &:after {
            content: '';
            position: absolute;
            width: 244px;
            height: 280px;
            top: 30px;
            left: -49px;
            background-image: url(../img/diamond.svg);
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
        }
        &:before {
            content: '';
            position: absolute;
            width: 193px;
            height: 280px;
            bottom: -163px;
            right: 0;
            background-image: url(../img/diamond.svg);
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
        }
        &__title {
            font-weight: 900;
            font-size: 8vw;
            line-height: 149px;
            color: #fff;
            text-align: right;
            padding-top: 5vh;
            padding-right: 5%;
            position: relative;
            padding-left: 300px;
            font-family: 'Source Sans Pro', sans-serif;
            &:after {
                content: '';
                position: absolute;
                width: 50%;
                height: 2px;
                bottom: -5vh;
                right: 0;
                background-color: #fff;
            }
        }
        &__text {
            font-size: 3vw;
            color: #fff;
            text-align: center;
            margin-top: 13%;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
        }
    }
    &__footer {
        display: none;
    }
}

@media only screen and (max-width: 1300px) {

    .coming-soon {
        &__header {
            &__left {
                width: 60vw;
            }
            &__right {
                width: 40vw;
                &__name {
                    font-size: 30px;
                }
                &__address {
                    font-size: 20px;
                }
            }
        }
        &__main {
            &__title {
                &:after {
                    bottom: -2vh;
                }
            }
            &__text {
                padding: 0 100px;
                margin-top: 18%;
            }
        }
    }

}

@media only screen and (max-width: 991px) {

    .coming-soon {
        &__header {
            &__left {
                width: 100vw;
                justify-content: center;
                padding-left: 0;
                background-image: none;
            }
            &__right {
                display: none;
                &__name {
                    margin: 0;
                }
                &__address {
                    margin-bottom: 0;
                }
            }
        }
        &__main {
            height: 60vh;
            &__text {
                font-size: 4vw;
            }
        }
        &__footer {
            background-image: url(../img/bg.png);
            background-repeat: no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            height: 15vh;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

}

@media (orientation: landscape) and (max-width: 991px) {

    .coming-soon {
        overflow: scroll;
        &__main {
            height: 500px;
        }
        &__footer {
            height: 120px;
        }
    }
}

@media only screen and (max-width: 767px) {

    .coming-soon {
        &__main {
            &:after {
                width: 180px;
                height: 207px;
            }
            &:before {
                width: 180px;
                height: 207px;
                bottom: -120px;
            }
            &__title {
                padding-left: 180px;
                font-size: 9vw;
                line-height: 11vw;
                margin: 5% 0;
                padding-bottom: 3%;
            }
            &__text {
                font-size: 6vw;
            }
        }
    }

}

@media only screen and (max-width: 590px) {

    .coming-soon {
        &__header {
            height: 15vh;
        }
        &__main {
            &:after {
                width: 130px;
                height: 149px;
            }
            &:before {
                width: 130px;
                height: 149px;
                bottom: -80px;
                right: -50px;
            }
            &__title {
                padding-left: 130px;
                font-size: 12vw;
                line-height: 14vw;
            }
            &__text {
                padding: 0 50px;
                font-size: 8vw;
                margin-top: 10%;
            }
        }
        &__footer {
            height: 25vh;
        }
    }

}

@media only screen and (max-width: 450px) {

    .coming-soon {
        &__header {
            height: 15vh;
        }
        &__main {
            &__text {
                padding: 0 50px;
                font-size: 8vw;
                margin-top: 20%;
            }
        }
    }

}