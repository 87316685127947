.map {
    padding-top: 250px;
    &__img {
        img {
            width: 100%;
        }
    }
    &__block {
        @extend .content-page__block;
        top: -70px;
        margin-bottom: -20px;
        z-index: 3;
        &__wrap {
            @extend .content-page__block__wrap;
            &:after {
                display: none;
            }
            &:before {
                left: 30%;
            }
        }
        &__title {
            text-align: center;
            border-bottom: 2px dotted $orange;
            padding-bottom: 30px;
            font-weight: 600;
            font-size: 48px;
            line-height: 56px;
            color: $font;
        }
        &__countries {
            position: relative;
            text-align: center;
            width: 50%;
            &--offices {
                padding-top: 70px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    background-image: url(../img/pointer-active.svg);
                }
            }
            &--planned {
                padding-top: 70px;
                &:before {
                    content: '';
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    left: 50%;
                    top: 0;
                    transform: translateX(-50%);
                    background-image: url(../img/pointer-inactive.svg);
                }
            }
            &__wrap {
                display: flex;
                flex-direction: row;
                margin-top: 40px;
            }
            &__title {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: $font;
            }
            &__text {
                font-size: 15px;
                line-height: 20px;
                color: $font;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {

    .map {
        &__block {
            top: -30px;
        }
    }

}

@media only screen and (max-width: 574px) {

    .map {
        &__block {
            top: 0;
            padding: 60px;
            margin-bottom: 20px;
            &__title {
                font-size: 34px;
                line-height: 44px;
            }
            &__countries {
                &__title {
                    font-size: 19px;
                    line-height: 26px;
                }
            }
        }
    }

}